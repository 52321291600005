
import { Component, Prop, Vue } from "vue-property-decorator";
import { email, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { UFliterals } from "@/utils/literals";
import clientCache from "@/utils/cacheUtils";
import { UFFormValidationMessage } from "@/utils/literals/forms";

@Component({
  name: "OTPForm",
  mixins: [validationMixin],
  validations: {
    email: { required, email },
  },
})
export default class OTPForm extends Vue {
  @Prop({ type: String, required: true })
  readonly code: string | undefined;
  @Prop({ type: Function, required: true })
  responseHandler!: CallableFunction;

  private email = "";
  private emailSent = false;

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push(UFFormValidationMessage.invalidEmail);
    !this.$v.email.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  requestOTP(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.emailSent = true;
      this.$store.dispatch("sequence/getOTP", {
        email: this.email,
        code: this.code,
        next: (username: string, code: string) =>
          this.responseHandler(true, username, code),
      });
      // this.responseHandler(true, clientCache.get("username"));
      clientCache.cache("otp_email", this.email);
    }
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
