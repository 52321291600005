
import LoginForm from "@/components/authentication/LoginForm.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import SequenceForm from "@/components/sequence/SequenceForm.vue";
import JoinSequence from "@/components/sequence/JoinSequence.vue";
import { isNavigationFailure, NavigationFailureType } from "vue-router";

@Component({
  name: "LoginPage",
  components: { JoinSequence, SequenceForm, LoginForm },
})
export default class LoginPage extends Vue {
  @Prop({ required: false, type: String })
  student: "" | undefined;

  private tab = null;

  redirectToSequence(sequenceCode: string): void {
    this.$router
      .push({
        name: "sequenceJoin",
        params: {
          code: sequenceCode,
        },
      })
      .catch((failure) => {
        // if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
        // }
        // if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
        // }
        if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
          console.log(
            `Navigation cancelled from /login/student to /join/${sequenceCode} with a new navigation.`
          );
        }
      });
  }
}
