
import { Component, Vue } from "vue-property-decorator";
import SequenceForm from "@/components/sequence/SequenceForm.vue";
import { statusMessage } from "@/utils/httpUtils";
import OTPForm from "@/components/sequence/OTPForm.vue";
import { validationMixin } from "vuelidate";
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { UFliterals } from "@/utils/literals";
import clientCache from "@/utils/cacheUtils";
import { UFFormValidationMessage } from "@/utils/literals/forms";

@Component({
  name: "JoinSequence",
  components: { OTPForm, SequenceForm },
  mixins: [validationMixin],
  validations: {
    otp: {
      required,
      maxLength: maxLength(6),
      minLength: minLength(6),
    },
  },
})
export default class JoinSequence extends Vue {
  private sequenceCode = "";
  private display = false;
  private displayOTP = false;
  otp = "";

  get otpErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.otp.$dirty) return errors;
    !this.$v.otp.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(6));
    !this.$v.otp.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(6));
    !this.$v.otp.required && errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getUFLabel(key: string): string {
    return UFliterals[key] || "";
  }
  sequenceFormHandler(sequenceCode: string): void {
    this.sequenceCode = sequenceCode;
    this.$store.dispatch("sequence/fetchByCode", {
      code: sequenceCode,
      next: (exists: boolean) => {
        if (exists) {
          this.display = true;
        }
      },
      onError: (error: { message: string }) => {
        if (error.message === statusMessage["404"]) {
          this.$store.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText: this.getUFLabel("noSequenceFound"),
              snackColor: "error",
              snackTimeOut: 4000,
            },
            { root: true }
          );
        }
      },
    });
  }
  OTPFormHandler(emailSent: boolean, username?: string): void {
    if (emailSent) {
      this.displayOTP = true;
      if (!username) {
        this.$store.commit(
          "SET_SNACK",
          {
            displaySnack: true,
            snackText: this.getUFLabel("notDispatchedInSequence"),
            snackColor: "error",
            snackTimeOut: 4000,
          },
          { root: true }
        );
      }
    }
  }
  submit(): void {
    this.$store.dispatch("sequence/checkOTP", {
      sequenceCode: this.sequenceCode,
      otp: this.otp,
      email: clientCache.get("otp_email"),
      // TODO on error 404 not found { message: "user not approved" }
      next: (message: {
        status: string;
        uuid?: string;
        sequence_id?: string;
      }) => {
        if (message.status === "approved") {
          clientCache.cache("auth_uuid", message.uuid);
          this.$store.dispatch("getTokenFromQuickCreds", {
            credentials: {
              email: clientCache.get("otp_email"),
              auth_uuid: clientCache.get("auth_uuid"),
            },
            sequenceCode: this.sequenceCode,
            next: () => {
              clientCache.clear("otp");
              clientCache.clear("otp_email");
              clientCache.clear("auth_uuid");
              clientCache.clear(`sequence:${this.sequenceCode}:requested`);
              this.$router.push({
                name: "sequence",
                params: {
                  id: message.sequence_id || "",
                },
              });
            },
          });
        }
      },
    });
  }
}
