
import { Component, Prop, Vue } from "vue-property-decorator";
import { UFliterals } from "@/utils/literals";
import { validationMixin } from "vuelidate";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { UFFormValidationMessage } from "@/utils/literals/forms";
import { generateRandomString } from "@/utils";

@Component({
  name: "SequenceForm",
  mixins: [validationMixin],
  validations: {
    sequenceCode: {
      required,
      maxLength: maxLength(5),
      minLength: minLength(5),
    },
  },
})
export default class SequenceForm extends Vue {
  @Prop({ required: true, type: Function })
  formHandler!: CallableFunction;
  @Prop({ type: String, required: true })
  infoMessage!: string;

  private sequenceCode = "";

  get sequenceCodeErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.sequenceCode.$dirty) return errors;
    !this.$v.sequenceCode.minLength &&
      errors.push(UFFormValidationMessage.minLengthChar(5));
    !this.$v.sequenceCode.maxLength &&
      errors.push(UFFormValidationMessage.maxLengthChar(5));
    !this.$v.sequenceCode.required &&
      errors.push(UFFormValidationMessage.required);
    return errors;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  handler(): void {
    this.formHandler(this.sequenceCode);
  }
}
